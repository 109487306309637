<template>
  <BaseModal
    name="links-new"
    size="lg"
    title="⭐ Criar um Link"
    @shown="openModal"
    @hidden="resetItens"
  >
    <b-row v-if="all_edit === null">
      <b-col cols="12">
        <h5 class="produto-nome">• {{ product_name }}</h5>
        <div
          class="checks"
          v-if="
            (!loading && !$store.getters.setPanel) ||
              ($store.getters.setPanel && !link_read)
          "
        >
          <b-form-checkbox
            v-model="order_b"
            name="check-button"
            switch
            v-if="product_type !== 'SUBSCRIPTION' && product_installments"
            @change="changeCheckBox($event, 'orderbump')"
          >
            <p class="title-checkbox">Order Bump</p>
          </b-form-checkbox>
          <b-form-checkbox
            v-if="!isPhysical"
            v-model="checkout"
            name="check-button"
            switch
            @change="changeCheckBox($event, 'checkout')"
          >
            <p class="title-checkbox">Checkout Personalizado</p>
          </b-form-checkbox>
          <b-form-checkbox
            v-model="cupom"
            name="check-button"
            @change="changeCheckBox($event, 'cupom')"
            switch
          >
            <p class="title-checkbox">Cupom de Desconto</p>
          </b-form-checkbox>
        </div>

        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <b-form novalidate v-if="!loading">
          <b-form-group
            label="Título do Link"
            label-for="name"
            v-if="
              order_b ||
                checkout ||
                (cupom && !$store.getters.setPanel) ||
                ($store.getters.setPanel && !link_read)
            "
          >
            <b-form-input
              id="name"
              name="name"
              v-model="name"
              type="text"
              placeholder="Meu Link"
              v-validate="'required'"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('name')">
              Por favor, insira o título.
            </b-form-invalid-feedback>
          </b-form-group>
          <div v-if="order_b">
            <b-form-group
              label="Produto Principal"
              label-for="product-main"
              data-anima="top"
            >
              <b-form-input
                id="product-main"
                name="product-main"
                v-model="product_name"
                type="text"
                disabled
                data-form="modificado"
                data-anima="top"
              ></b-form-input>
            </b-form-group>
            <div v-if="offers">
              <b-form-group label="Selecione a oferta (Opcional)">
                <multiselect
                  data-anima="top"
                  v-model="offer_select"
                  placeholder="Selecione a oferta"
                  label="name"
                  track-by="id"
                  :options="filterByOffer(offers)"
                  :multiple="false"
                  :taggable="false"
                >
                  <span slot="noOptions">Nenhuma oferta encontrada.</span>
                  <span slot="noResult"
                    >Nenhuma oferta encontrada.</span
                  ></multiselect
                >
              </b-form-group>
              <div class="mark-down mb-3">
                <p class="info-mark">
                  Caso não selecione uma oferta, será utilizado a oferta padrão
                </p>
              </div>
            </div>
            <h5 class="label-title" data-anima="top">Order bump</h5>
            <b-form-group label="Produto Order Bump">
              <multiselect
                v-if="type"
                data-anima="top"
                v-model="product_order_b"
                placeholder="Selecione um Produto para começar"
                label="name"
                @select="getOffers"
                :name="`link-${productsA.id}`"
                track-by="id"
                :options="filterBy(productsA)"
                :multiple="false"
                :taggable="false"
                ><span slot="noResult"
                  >Nenhum Link encontrado.</span
                ></multiselect
              >
              <multiselect
                v-else
                data-anima="top"
                v-model="product_order_b"
                placeholder="Selecione um Produto para começar"
                label="name"
                :name="`link-${productsS.id}`"
                track-by="id"
                :options="filterBy(productsS)"
                @select="getOffers"
                :multiple="false"
                :taggable="false"
                ><span slot="noResult"
                  >Nenhum Link encontrado.</span
                ></multiselect
              >
            </b-form-group>
            <div v-if="JSON.stringify(product_order_b) !== '[]'">
              <!-- <h5 class="label-title" data-anima="top">Oferta Bump (Opcional)</h5> -->
              <b-form-group label="Selecione a oferta bump (Opcional)">
                <multiselect
                  data-anima="top"
                  v-model="offer_bump_select"
                  placeholder="Selecione a oferta"
                  label="name"
                  track-by="id"
                  :options="filterByOffer(offers_bump)"
                  :multiple="false"
                  :taggable="false"
                >
                  <span slot="noOptions">Nenhuma oferta encontrada.</span>
                  <span slot="noResult"
                    >Nenhuma oferta encontrada.</span
                  ></multiselect
                >
              </b-form-group>
              <div class="mark-down mb-3">
                <p class="info-mark">
                  Caso não selecione uma oferta, será utilizado a oferta padrão
                </p>
              </div>
            </div>
          </div>
          <div v-if="checkout && !$store.getters.setPanel">
            <b-form-group
              v-if="!order_b"
              label="Produto Principal"
              label-for="product-main"
              data-anima="top"
            >
              <b-form-input
                id="product-main"
                name="product-main"
                v-model="product_name"
                type="text"
                disabled
                data-form="modificado"
                data-anima="top"
              ></b-form-input>
            </b-form-group>
            <div v-if="offers && !order_b">
              <b-form-group label="Selecione a oferta (Opcional)">
                <multiselect
                  data-anima="top"
                  v-model="offer_select"
                  placeholder="Selecione a oferta"
                  label="name"
                  track-by="id"
                  :options="offers"
                  :multiple="false"
                  :taggable="false"
                >
                  <span slot="noOptions">Nenhuma oferta encontrada.</span>
                  <span slot="noResult"
                    >Nenhuma oferta encontrada.</span
                  ></multiselect
                >
              </b-form-group>
              <div class="mark-down mb-3">
                <p class="info-mark">
                  Caso não selecione uma oferta, será utilizado a oferta padrão
                </p>
              </div>
            </div>
            <h5 class="label-title" data-anima="top">Checkout Personalizado</h5>
            <b-form-group label="Selecione o Tema do Checkout">
              <multiselect
                data-anima="top"
                v-model="checkout_select"
                placeholder="Selecione um Tema do Checkout"
                label="name"
                :name="`link-${checkouts.id}`"
                track-by="id"
                :options="checkouts"
                :multiple="false"
                :custom-label="customLabel"
                :taggable="false"
                ><span slot="noResult"
                  >Nenhum Link encontrado.</span
                ></multiselect
              >
            </b-form-group>
          </div>

          <div v-if="cupom">
            <b-form-group
              v-if="!order_b"
              label="Produto Principal"
              label-for="product-main"
              data-anima="top"
            >
              <b-form-input
                id="product-main"
                name="product-main"
                v-model="product_name"
                type="text"
                disabled
                data-form="modificado"
                data-anima="top"
              ></b-form-input>
            </b-form-group>
            <div v-if="offers && !order_b">
              <b-form-group label="Selecione a oferta (Opcional)">
                <multiselect
                  data-anima="top"
                  v-model="offer_select"
                  placeholder="Selecione a oferta"
                  label="name"
                  track-by="id"
                  :options="offers"
                  :multiple="false"
                  :taggable="false"
                >
                  <span slot="noOptions">Nenhuma oferta encontrada.</span>
                  <span slot="noResult"
                    >Nenhuma oferta encontrada.</span
                  ></multiselect
                >
              </b-form-group>
              <div class="mark-down mb-3">
                <p class="info-mark">
                  Caso não selecione uma oferta, será utilizado a oferta padrão
                </p>
              </div>
            </div>
            <h5 class="label-title" data-anima="top">Cupom de desconto</h5>
            <b-form-group label="Selecione o cupom">
              <multiselect
                data-anima="top"
                v-model="cupom_select"
                placeholder="Selecione o cupom"
                label="name"
                track-by="id"
                :options="cupons"
                :multiple="false"
                :taggable="false"
              >
                <span slot="noOptions">Nenhum Cupom encontrado.</span>
                <span slot="noResult"
                  >Nenhum Cupom encontrado.</span
                ></multiselect
              >
            </b-form-group>
          </div>
          <!-- Generated Link Create -->
          <div v-if="order_b || checkout || cupom">
            <div data-anima="top">
              <b-form-group label="Link gerado">
                <b-form-input
                  ref="linkconfig"
                  name="url"
                  :value="
                    'https://pay.voompcreators.com.br/' +
                      product_id +
                      formatId(
                        product_order_b,
                        checkout_select,
                        cupom_select,
                        offer_select,
                        offer_bump_select
                      )
                  "
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div v-if="!order_b && !checkout && !cupom">
            <div>
              <!-- <b-row>
                <b-col cols="12" md="6"> -->
              <b-form-group label="Insira a URL" label-for="url">
                <b-form-input
                  id="url"
                  name="url"
                  v-model="url"
                  type="text"
                  placeholder="https://www.voomp.com.br/"
                  v-validate="{ url: { require_protocol: true } }"
                  data-form="modificado"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('url')">
                  Por favor, insira uma URL válida.
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- </b-col> -->

              <!-- <b-col cols="12" md="6"> -->
              <b-form-group label="Título do Link" label-for="name">
                <b-form-input
                  id="name"
                  name="name"
                  v-model="name"
                  type="text"
                  placeholder="Meu Link"
                  v-validate="'required'"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  Por favor, insira o título.
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- </b-col>
              </b-row> -->

              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Fonte (opcional)" label-for="source">
                    <b-form-input
                      id="url"
                      name="source"
                      v-model="source"
                      type="text"
                      placeholder="utm_source"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('source')">
                      Por favor, insira a fronte.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                  <b-form-group label="Médio (opcional)" label-for="medium">
                    <b-form-input
                      id="medium"
                      name="medium"
                      v-model="medium"
                      type="text"
                      placeholder="facebook, email"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('medium')">
                      Por favor, insira o médio.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col cols="12">
        <h5 class="produto-nome">• {{ product_name }}</h5>

        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <b-form novalidate v-if="!loading">
          <b-form-group
            label="Título do Link"
            label-for="name"
            v-if="order_b || checkout || cupom"
          >
            <b-form-input
              id="name"
              name="name"
              v-model="name"
              type="text"
              placeholder="Meu Link"
              v-validate="'required'"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('name')">
              Por favor, insira o título.
            </b-form-invalid-feedback>
          </b-form-group>
          <div v-if="all_edit.is_bump === 1">
            <h5 class="label-title" data-anima="top">Order Bump</h5>
            <b-form-group
              label="Produto Principal"
              label-for="url"
              data-anima="top"
            >
              <b-form-input
                id="url"
                name="url"
                v-model="product_name"
                type="text"
                disabled
                data-form="modificado"
                data-anima="top"
              ></b-form-input>
            </b-form-group>
            <div v-if="offers">
              <!-- <h5 class="label-title" data-anima="top">Oferta Bump (Opcional)</h5> -->
              <b-form-group label="Selecione a oferta (Opcional)">
                <multiselect
                  data-anima="top"
                  v-model="offer_select"
                  placeholder="Selecione a oferta"
                  label="name"
                  track-by="id"
                  :options="offers"
                  :multiple="false"
                  :taggable="false"
                >
                  <span slot="noOptions">Nenhuma oferta encontrada.</span>
                  <span slot="noResult"
                    >Nenhuma oferta encontrada.</span
                  ></multiselect
                >
              </b-form-group>
            </div>
            <b-form-group label="Produto Order Bump">
              <multiselect
                data-anima="top"
                v-model="product_order_b"
                placeholder="Selecione um Produto para começar"
                label="name"
                :name="`link-${productsS.id}`"
                track-by="id"
                :options="filterBy(productsS)"
                :multiple="false"
                :taggable="false"
                ><span slot="noResult"
                  >Nenhum Link encontrado.</span
                ></multiselect
              >
            </b-form-group>
            <div v-if="JSON.stringify(product_order_b) !== '[]'">
              <!-- <h5 class="label-title" data-anima="top">Oferta Bump (Opcional)</h5> -->
              <b-form-group label="Selecione a oferta bump (Opcional)">
                <multiselect
                  data-anima="top"
                  v-model="offer_bump_select"
                  placeholder="Selecione a oferta"
                  label="name"
                  track-by="id"
                  :options="offers_bump"
                  :multiple="false"
                  :taggable="false"
                >
                  <span slot="noOptions">Nenhuma oferta encontrada.</span>
                  <span slot="noResult"
                    >Nenhuma oferta encontrada.</span
                  ></multiselect
                >
              </b-form-group>
            </div>
          </div>
          <!-- Generated Link Update -->
          <div v-if="all_edit.is_bump === 1">
            <div data-anima="top">
              <b-form-group label="Link gerado">
                <b-form-input
                  ref="linkconfig"
                  name="url"
                  :value="
                    'https://pay.voompcreators.com.br/' +
                      product_id +
                      formatId(
                        product_order_b,
                        checkout_select,
                        cupom_select,
                        offer_select,
                        offer_bump_select
                      )
                  "
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        variant="secondary_outline"
        :disabled="loading"
        @click="cancel"
        class="mr-4"
      >
        Cancelar
      </BaseButton>
      <BaseButton
        variant="info3"
        class="mr-md-4"
        :disabled="loading"
        @click="copyLink"
      >
        Copiar Link
      </BaseButton>
      <BaseButton
        variant="secondary"
        v-if="
          (all_edit === null && !$store.getters.setPanel) ||
            ($store.getters.setPanel && !link_read)
        "
        :disabled="loading"
        @click="onSubmit"
      >
        Cadastrar Link
      </BaseButton>
      <BaseButton
        variant="secondary"
        v-if="all_edit !== null && !$store.getters.setPanel"
        :disabled="loading"
        @click="onUpdate"
      >
        Atualizar Link
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";

import LinkService from "@/services/resources/LinkService";
const service = LinkService.build();
import CouponService from "@/services/resources/CouponService";
const serviceCoupon = CouponService.build();
import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

export default {
  components: {
    Multiselect,
  },
  props: {
    link_read: {
      type: Boolean,
      default: false,
    },
    product_installments: {
      type: Boolean,
    },
    checkouts: {
      type: Array,
    },
    _cupom: {},
    has_orderbump: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Boolean,
    },
    productsS: {
      type: Array,
    },
    productsA: {
      type: Array,
    },
    product_id: {
      type: Number,
      default: -1,
    },
    product_type: {
      type: String,
    },
    product_name: {
      type: String,
      default: "",
    },
    all_edit: {
      type: Object,
    },
    "is-physical": {
      type: Boolean
    }
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId === "links-new") {
        this.resetItens();
      }
    });
  },

  data() {
    return {
      url: "",
      name: "",
      source: "",
      medium: "",
      submit: false,
      loading: false,
      cupom: false,
      checkout: false,
      order_b: false,
      product_order_b: null,
      checkout_select: null,
      cupom_select: null,
      offer_select: null,
      offer_bump_select: null,
      cupons: [],
      offers: [],
      offers_bump: [],
    };
  },
  methods: {
    openModal() {
      if (this.all_edit !== null) {
        this.product_order_b = this.all_edit.product_order_b;
        this.offer_select = this.all_edit.offer_select
          ? this.all_edit.offer_select
          : null;
        this.offer_bump_select = this.all_edit.offer_bump_select
          ? this.all_edit.offer_bump_select
          : null;

        if (this.offer_select) {
          this.getOffers();
        }
        if (this.offer_bump_select) {
          this.getOffers({ id: this.offer_bump_select.product_id });
        }
      }
      if (this._cupom && this.$parent.cupom) {
        this.cupom = true;
        this.changeCheckBox(true, "cupom");
        this.cupom_select = this._cupom;

        this.$bvToast.toast("Continue gerando o link do Cupom", {
          title: "Links - Cupom",
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } else {
        this.cupom = false;
        this.cupom_select = null;
      }

      if (this.has_orderbump) {
        this.order_b = true;
      }

      this.cupons = [];
      this.offers = [];
      this.offers_bump = [];
    },
    getOffers(bump) {
      this.loading = true;

      let data = {
        id: this.product_id + "/offers",
      };

      if (bump) {
        data.id = bump.id + "/offers";
      }

      serviceProduct
        .read(data)
        .then((response) => {
          if (!bump) {
            this.offers = [];
            response.forEach((el) => {
              this.offers.push({
                id: el.id,
                name: el.name,
                max_boleto_installments: el.max_boleto_installments,
                hash: el.hash,
              });
            });
          } else {
            this.offers_bump = [];
            if (this.all_edit === null) {
              this.offer_bump_select = null;
            }
            response.forEach((el) => {
              this.offers_bump.push({
                id: el.id,
                name: el.name,
                max_boleto_installments: el.max_boleto_installments,
                hash: el.hash,
              });
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCupom() {
      this.loading = true;

      let data = {
        id: `product/${this.product_id}`,
      };

      this.cupons = [];

      serviceCoupon
        .read(data)
        .then((response) => {
          response.forEach((el) => {
            this.cupons.push({
              id: el.id,
              name: el.name,
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeCheckBox(event, type) {
      // console.log(this.productsS);
      if (event) {
        if (type == "cupom") {
          this.getCupom();
        }
        this.offer_select = null;
        this.getOffers();
      } else {
        if (type == "cupom") {
          this.cupom_select = null;
        } else if (type == "checkout") {
          this.checkout_select = null;
        } else if (type == "orderbump") {
          this.product_order_b = null;
        }
      }
    },
    filterBy(data) {
      return data.filter((data) => {
        if (
          this.product_id == data.id ||
          data.format === "PHYSICALPRODUCT" ||
          data.type === "SUBSCRIPTION"
        ) {
          return data;
        } else {
          return data;
        }
      });
    },
    filterByOffer(data) {
      return data.filter((data) => {
        if (
          data.max_boleto_installments !== 0 &&
          data.max_boleto_installments !== null
        ) {
          return (data.$isDisabled = true);
        } else {
          return data;
        }
      });
    },
    customLabel({ title }) {
      return `${title}`;
    },
    copyLink() {
      if (this.$refs.linkconfig) {
        navigator.clipboard.writeText(this.$refs.linkconfig.value);
        this.$bvToast.toast("Link copiado para sua área de transferência", {
          title: "Links",
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    resetItens() {
      this.order_b = false;
      this.cupom = false;
      this.checkout = false;
      this.product_order_b = [];
      this.checkout_select = null;
      this.cupom_select = null;
      this.$parent.cupom = null;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    formatId(orderb, checkout, cupom, offer_select, offer_bump_select) {
      var link = "";

      if (orderb && orderb.length) {
        orderb = orderb[0];
      }

      if (JSON.stringify(orderb) === "[]") {
        orderb = null;
      }
      if (!orderb && checkout === null && cupom === null) {
        link = "";
      } else if (orderb && checkout === null && cupom === null) {
        link = `/?b_id=${orderb.id}`;
      } else if (orderb && checkout !== null && cupom === null) {
        link = `/?b_id=${orderb.id}&ch_id=${checkout.id}`;
      } else if (!orderb && checkout !== null && cupom === null) {
        link = `/?ch_id=${checkout.id}`;
      } else if (!orderb && checkout === null && cupom !== null) {
        link = `/?cupom=${cupom.name}`;
      } else if (!orderb && checkout !== null && cupom !== null) {
        link = `/?ch_id=${checkout.id}&cupom=${cupom.name}`;
      }
      else if (orderb && checkout !== null && cupom !== null) {
        if (
          this.checkout === true &&
          this.order_b === true &&
          this.cupom === true
        ) {
          link = `/?b_id=${orderb.id}&ch_id=${checkout.id}&cupom=${cupom.name}`;
        } else if (
          this.checkout === false &&
          this.order_b == true &&
          this.cupom == false
        ) {
          link = `/?b_id=${orderb.id}`;
        } else if (
          this.checkout === true &&
          this.order_b === false &&
          this.cupom == false
        ) {
          link = `/?ch_id=${checkout.id}`;
        } else if (
          this.checkout === false &&
          this.order_b === false &&
          this.cupom == true
        ) {
          link = `/?cupom=${cupom.name}`;
        }
      }

      console.log(link);

      if(offer_select){
        let link_base = link;
        link = "/offer/" + offer_select.hash + link_base;
      }

      if (offer_bump_select) {
        let link_base = link;
        link = link_base
          ? link_base + `&b_offer=${offer_bump_select.hash}`
          : link_base + `/?b_offer=${offer_bump_select.hash}`;
      }

      if (this.type) {
        const queryAfiliado = `a_id=${this.userID()}`;
        link = link ? link + `&${queryAfiliado}` : link + `/?${queryAfiliado}`;
      }
      return link;
    },
    onUpdate() {
      let data = {
        id: this.all_edit.id,
        url: this.$refs.linkconfig.value,
      };

      service
        .update(data)
        .then(() => {
          this.$emit("close");
          this.$bvModal.hide("links-new");
          this.$bvToast.toast("Link atualizado com sucesso", {
            title: "Links",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
    onSubmit() {
      this.submit = true;
      this.loading = true;

      let urlMode = "";
      if (
        this.checkout === true ||
        this.order_b === true ||
        this.cupom === true
      ) {
        urlMode = this.$refs.linkconfig.value;
      } else {
        let newQuery = this.source ? `${this.source}=${this.medium}` : "";

        urlMode = this.url;
        let has_query = new URL(urlMode);

        if (this.source) {
          urlMode += has_query.search ? `&${newQuery}` : `?${newQuery}`;
        }
      }

      this.$validator
        .validateAll()
        .then((result) => {
          if (result) {
            if (this.order_b === true && this.product_order_b === null) {
              this.$bvToast.toast(
                "Necessário selecionar um produto order bump",
                {
                  title: "Links",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              return;
            }

            if (this.checkout === true && this.checkout_select === null) {
              this.$bvToast.toast("Necessário selecionar um tema", {
                title: "Links",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              return;
            }

            let data = {
              url: urlMode,
              name: this.name,
              source: this.source,
              medium: this.medium,
              product_id: this.product_id,
            };

            if (this.order_b) {
              data.is_bump = true;
            }

            if (this.offer_select) {
              data.offer_select = this.offer_select;
            }

            if (this.offer_bump_select) {
              data.offer_bump_select = this.offer_bump_select;
            }

            service
              .create(data)
              .then(() => {
                this.$emit("close");
                this.url = "";
                this.name = "";
                this.source = "";
                this.medium = "";
                this.$bvModal.hide("links-new");
                this.$bvToast.toast("Link criado com sucesso", {
                  title: "Links",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });

                if (this._cupom) {
                  this.$parent.cupom = null;
                }
              })
              .finally(() => {
                this.submit = false;
                this.loading = false;
              });
          } else {
            this.submit = false;
            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.produto-nome {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #0d0d0f;
}

.info-mark {
  margin: 0;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
}

.label-title {
  font-size: 12px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #2a63ab;
}

.title-checkbox {
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.2px;
  color: #333333;
  font-weight: normal;
}
.checks {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}
.checks .title-checkbox {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .checks {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}
</style>
